import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function guardMyroute(to, from, next) {
	var isAuthenticated= false;

	if (store.state.loggedin) {
		isAuthenticated = true;
	} else {
		isAuthenticated= false;
	}
		
	if (isAuthenticated) {
		next();
	} else {
		router.push({name: 'Login'})
	}
}

const routes = [
	{
		path: '/',
		name: 'Order',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/confirm',
		name: 'Confirm',
		component: () => import(/* webpackChunkName: "about" */ '../views/Confirm.vue')
	},
	{
		path: '/end',
		name: 'End',
		component: () => import(/* webpackChunkName: "about" */ '../views/End.vue')
	},
	{
		path: '/private/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
	},
	{
		path: '/private/orders',
		name: 'AllOrders',
		beforeEnter : guardMyroute,
		component: () => import(/* webpackChunkName: "about" */ '../views/AllOrders.vue')
	},
	{
		path: '/private/add_orders',
		name: 'AddOrders',
		beforeEnter : guardMyroute,
		component: () => import(/* webpackChunkName: "about" */ '../views/AddOrders.vue')
	},
	{
		path: '/private/add_orders_menu',
		name: 'AddMenuRecipes',
		beforeEnter : guardMyroute,
		component: () => import(/* webpackChunkName: "about" */ '../views/AddMenu.vue')
	},
	{
		path: '/private/delete_recipes',
		name: 'DeleteRecipes',
		beforeEnter : guardMyroute,
		component: () => import(/* webpackChunkName: "about" */ '../views/DeleteRecipes.vue')
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

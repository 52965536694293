import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Vuelidate from 'vuelidate';
//import VueAxios from 'vue-axios'

Vue.config.productionTip = false
//Vue.use(VueAxios)
Vue.use(axios)
Vue.use(Buefy)
Vue.use(Vuelidate);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
